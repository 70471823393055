import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Content, Title } from './SectionFive.styles'
import { ItemService } from '../../../../commons/ServiceItem'
import { MainButton } from '../../../../commons/MainButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFive = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-five',
        'section-five-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="diferencial">
      <Title>
        Diferencial Competitivo: <br /> Paixão pelo bem-estar integral
      </Title>
      <Content>
        <ItemService
          title="Comprometimento"
          description="Acreditamos no poder transformador do bem-estar e buscamos constantemente aprimorar nossos serviços."
          onClick={async () => {}}
          onMouseOver={async () => {}}
        />
        <ItemService
          title="Inovação"
          description="Utilizamos as melhores práticas e ferramentas para oferecer soluções inovadoras e personalizadas."
          onClick={async () => {}}
          onMouseOver={async () => {}}
        />
        <ItemService
          title="Empatia"
          description="Entendemos as necessidades e desafios dos colaboradores, buscando soluções eficazes para promover o bem-estar."
          action={
            <MainButton
              title="Solicitar Treinamento"
              onClick={async () => {}}
              onMouseOver={async () => {
                await HandleLead(
                  'mouse-over',
                  'section-five',
                  'section-five-main-button'
                )
              }}
            />
          }
          onClick={async () => {}}
          onMouseOver={async () => {}}
        />
      </Content>
    </Container>
  )
}
