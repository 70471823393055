import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 6rem;
  border-top: 1px solid ${(props) => props.theme.colors.quaternary};
  padding: 1.75rem 0;

  ${screens.tablet`
    flex-direction: column;
    gap: 1rem
  `}
  ${screens.mobile`
      flex-direction: column;
      gap: 1rem
    `}
`

export const Title = styled.h1`
  flex: 1;
  width: 34rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.tertiary};

  ${screens.tablet`
    width: 24rem;
    font-size: 4vw;
  `}
  ${screens.mobile`
    width: 18rem;
    font-size: 4vw;
    `}
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`

export const Description = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.black};
  line-height: 1.75rem;
  max-width: 21rem;

  ${screens.tablet`
    font-size: 2.5vw;
  `}
  ${screens.mobile`
    font-size: 2.5vw;
    `}
`
