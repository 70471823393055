import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Button = styled.button`
  all: unset;
  width: 20rem;
  height: 3.5rem;
  border-radius: 9999px;
  background: ${(props) => props.theme.colors.tertiary};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  text-align: center;

  ${screens.mobile`
    width: 18rem;
    font-size: 0.9rem;
  `}
`
