import {
  Pat1,
  Pat2,
  Pat3,
  Pat4,
  Pat5,
  Pat6,
  Pat7,
  Pat8,
  Pat9,
  Pat10,
  Pat11,
} from '@/../../src/assets/images'

import {
  Carousel,
  CarouselItem,
  CarouselWrapper,
  Container,
  Image,
} from './SectionTwo.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionTwo = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-two')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-two',
        'section-two-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-two">
      <CarouselWrapper>
        <Carousel>
          <CarouselItem>
            <Image src={Pat1} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat2} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat3} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat4} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat5} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat6} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat7} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat8} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat9} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat10} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat11} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat1} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat2} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat3} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat4} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat5} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat6} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat7} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat8} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat9} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat10} />
          </CarouselItem>
          <CarouselItem>
            <Image src={Pat11} />
          </CarouselItem>
        </Carousel>
      </CarouselWrapper>
    </Container>
  )
}
