import styled from 'styled-components'
import { screens } from '../../../../../types/screens'
import { backgroundImage, sectionImage } from '@/../../src/assets/images'
import alemdoriso2 from '@/../../src/assets/images/alemdoriso2.png'

export const Container = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: grid;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  grid-template-columns: 1fr 10fr 6fr;
  grid-template-rows: minmax(100vh, auto);
  grid-template-areas: '..... content image';

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(244, 163, 77, 0.93);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  ${screens.laptop`
    grid-template-columns: 1fr 5fr 4fr;
  `}

  ${screens.tablet`
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      '..... content .....'
      '..... image .....';
    height: auto;
  `}

  ${screens.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'content'
      'image';
    height: auto;
  `}
`

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  ${screens.tablet`
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 8rem 0 2rem 0;
  `}

  ${screens.mobile`
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 8rem 0 2rem 0;
  `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3.15rem;
  font-weight: bold;
  max-width: 46rem;
  color: ${(props) => props.theme.colors.tertiary};

  ${screens.laptop`
    font-size: 2.2rem;
    max-width: 32rem;
  `}

  ${screens.tablet`
    font-size: 1.8rem;
    max-width: 80%;
    text-align: center;
  `}

  ${screens.mobile`
    width: 90%;
    font-size: 1.5rem;
    text-align: center;
  `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.3rem;
  max-width: 40rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  line-height: 1.5rem;
  margin-top: 1rem;

  ${screens.laptop`
    font-size: 0.9rem;
    max-width: 28rem;
  `}

  ${screens.tablet`
    font-size: 1.8vw;
    max-width: 100%;
    text-align: center;
  `}

  ${screens.mobile`
    width: 80%;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
  `}
`

export const Action = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 1.5rem;
  justify-content: flex-start;

  ${screens.tablet`
    justify-content: center;
  `}

  ${screens.mobile`
    justify-content: center;
  `}
`

export const Image = styled.div`
  grid-area: image;
  width: auto;
  max-width: 450px;
  padding: 50vh 16rem;
  height: auto;
  background-image: url(${sectionImage});
  opacity: 0.85;
  background-size: cover;
  background-position: center;
  justify-self: center;
  align-self: center;

  ${screens.laptop`
    padding: 50vh 10rem;
    max-width: 350px;
  `}

  @media (max-width: 1024px) {
    background-image: url(${alemdoriso2});
    padding: 7.15rem 50vw;
    max-width: 150px;
  }

  @media (max-width: 767px) {
    background-image: url(${alemdoriso2});
    padding: 6.15rem 50vw;
    max-width: 450px;
  }
`
