import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 8rem 14rem;
  background: ${(props) => props.theme.colors.white};

  ${screens.laptop`
       padding: 3rem 8rem;
    `}

  ${screens.tablet`
       padding: 3rem;
    `}

  ${screens.mobile`
       padding: 3rem 1.25rem;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 4.5rem;
  text-align: center;

  ${screens.laptop`
      font-size: 2.5rem;
    `}

  ${screens.tablet`
      font-size: 2rem;
    `}

  ${screens.mobile`
      font-size: 2rem;
    `}
`

export const QuestionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`
