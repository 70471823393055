import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.header`
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`

export const Content = styled.div<{ active?: boolean }>`
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  align-items: center;
  grid-template-columns:
    minmax(9.5rem, 1fr) minmax(auto, 2fr) minmax(auto, 8fr) minmax(auto, 2fr)
    minmax(7.5rem, 1fr);
  grid-template-rows: 7.125rem;
  grid-template-areas: '..... logo links action .....';

  ${screens.laptop`
      align-items: center;
      grid-template-columns:
      minmax(2.5rem, 1fr) minmax(auto, 2fr) minmax(auto, 8fr) minmax(auto, 2fr)
      minmax(2.5rem, 1fr);
      grid-template-rows: 5.5rem;
      grid-template-areas: '..... logo links action .....';
    `}

  ${screens.tablet`
      grid-template-columns:
      minmax(1.25rem, .5fr) repeat(2, minmax(auto, 4.5fr)) minmax(1.25rem, .5fr);
      grid-template-rows: 5rem;
      grid-template-areas: '..... logo icon .....'
    `}

  ${screens.mobile`
    grid-template-columns:
    minmax(1.25rem, .5fr) repeat(2, minmax(auto, 4.5fr)) minmax(1.25rem, .5fr);
    grid-template-rows: 5rem;
    grid-template-areas: '..... logo icon .....'
    `}
`

export const LogoImage = styled.img`
  grid-area: logo;
  width: 10rem;
  height: auto;
  cursor: pointer;

  ${screens.tablet`
    width: 10rem;    
    margin-left: -.5rem
    `}

  ${screens.mobile`
    width: 8rem;    
    margin-left: -.5rem
    `}
`

export const Links = styled.div`
  grid-area: links;
  display: flex;
  gap: 2rem;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  ${screens.tablet`
        display: none;
    `}

  ${screens.mobile`
        display: none;
    `}
`

export const Action = styled.div`
  grid-area: action;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-self: end;

  ${screens.tablet`
        display: none;
    `}

  ${screens.mobile`
        display: none;
    `}
`

export const Link = styled.a`
  all: unset;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.tertiary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }
`

export const Icon = styled.div`
  grid-area: icon;
  justify-self: flex-end;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  display: none;

  ${screens.tablet`
         display: block;
         font-size: 2.5rem;
    `}

  ${screens.mobile`
         display: block;
         font-size: 2rem;
    `}
`

export const MobileOptions = styled.div<{ active?: boolean }>`
  width: 100%;
  height: ${(props) => (props.active ? 'calc(100vh - 4rem)' : '0rem')};
  position: absolute;
  backdrop-filter: blur(12px);
  background-color: ${(props) =>
    props.active ? props.theme.colors.secondary : 'rgba(0, 0, 0, 0.5)'};
  top: 100%;
  left: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`

export const MobileActions = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  padding: 2rem 1.5rem;
`
