import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import {
  Advantages,
  Container,
  Content,
  Text,
  Title,
} from './SectionSix.styles'
import { AdvantageItem } from '../../../../commons/AdvantageItem'
import { AiOutlineCheckCircle } from 'react-icons/ai'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionSix = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-six')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-six',
        'section-six-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="solucoes">
      <Content>
        <Title>Soluções que transformam a sua empresa</Title>
        <Text>
          | Transforme o trabalho com soluções <br />| focadas no bem-estar e
          produtividade.
        </Text>
      </Content>
      <Advantages>
        <AdvantageItem
          icon={<AiOutlineCheckCircle />}
          title="Palestras Inspiradoras"
          content="Abordando temas como gestão do
estresse e inteligência emocional,
com linguagem leve e prática."
        />
        <AdvantageItem
          icon={<AiOutlineCheckCircle />}
          title="Dinâmicas Engajadoras"
          content="Atividades interativas para promover
a colaboração, maior empatia,
desenvolvimento pessoal e o
trabalho em equipe."
          colorful
        />
        <AdvantageItem
          icon={<AiOutlineCheckCircle />}
          title="Vivências
Transformadoras"
          content="Experiências imersivas que
exploram o autoconhecimento e a
conexão humana, promovendo
bem-estar físico, mental e
emocional."
        />
      </Advantages>
    </Container>
  )
}
