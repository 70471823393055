import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { LeadModel } from '../../../types/leadModel'
import {
  Actions,
  Container,
  Content,
  Copyright,
  Info,
  InfoIcon,
  InfoLink,
  Infos,
  InfoText,
  Link,
  Links,
  LogoImage,
  PolicyPrivacy,
  PolicyPrivacyLink,
  Social,
  Socials,
  Terms,
  Title,
} from './Footer.styles'

import Logo from '@/../../src/assets/images/logo.png'
import { RiPhoneLine, RiMailLine, RiInstagramLine, RiLinkedinLine } from 'react-icons/ri'
import useTimeSpentInSection from '../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Footer = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('footer')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead('time-spent', 'footer', 'footer-container', timeSpent / 1000)
  }, [timeSpent])

  return (
    <Container id="footer">
      <Content>
        <Title>Superando limites e criando novas oportunidades para o sucesso.</Title>
        <Infos>
          {<Info>
            <InfoIcon>
              <RiPhoneLine />
            </InfoIcon>
            <InfoText>+55 (11) 94706-0577</InfoText>
          </Info>}
          <Info>
            <InfoIcon>
              <RiMailLine />
            </InfoIcon>
            <InfoLink href="mailto:helena@elevaalma.com.br">
              helena@elevaalma.com.br
            </InfoLink>
          </Info>
          <Info>
            <InfoIcon>
              <RiInstagramLine />
            </InfoIcon>
            <InfoLink href="">
              @elevaalmaoficial
            </InfoLink>
          </Info>
          <Info>
            <InfoIcon>
              <RiLinkedinLine />
            </InfoIcon>
            <InfoLink href="https://www.linkedin.com/in/helena-guitler-nogueira/">
              /in/helena-guitler-nogueira
            </InfoLink>
          </Info>
        </Infos>
      </Content>
      <Actions>
        <LogoImage src={Logo} />
        <Links>
          <Link href="#qualidades">Qualidades</Link>
          <Link href="#biografia">Biografia</Link>
          <Link href="#diferencial">Diferencial</Link>
          <Link href="#solucoes">Soluções</Link>
          <Link href="#faq">FAQ</Link>
        </Links>
        <Socials>
          <Social>
            <FaFacebook href="" />
          </Social>
          <Social>
            <FaInstagram href="" />
          </Social>
          <Social>
            <FaLinkedin href="" />
          </Social>
        </Socials>
      </Actions>
      <Terms>
        <Copyright>© Todos os direitos reservados</Copyright>{' '}
        <PolicyPrivacy>
          <PolicyPrivacyLink href="#footer">Termos e Condições</PolicyPrivacyLink>{' '}
          <PolicyPrivacyLink href="#footer">Política de Privacidade</PolicyPrivacyLink>
        </PolicyPrivacy>
      </Terms>
    </Container>
  )
}
