import { useState } from 'react'
import {
  Answer,
  Container,
  Question,
  QuestionContainer,
} from './CommonQuestion.styles'
import { FaMinus, FaPlus } from 'react-icons/fa'

interface Props {
  question: string
  answer: string
}

export const CommonQuestion = ({ question, answer }: Props) => {
  const [active, setActive] = useState(false)

  return (
    <Container>
      <QuestionContainer active={active}>
        <Question active={active}>{question}</Question>
        {active ? (
          <FaMinus onClick={() => setActive(false)} />
        ) : (
          <FaPlus onClick={() => setActive(true)} />
        )}
      </QuestionContainer>
      <Answer active={active}>{answer}</Answer>
    </Container>
  )
}
