import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Advantages, Container, Title } from './SectionThree.styles'
import { AdvantageItem } from '../../../../commons/AdvantageItem'
import { AiOutlineBarChart } from 'react-icons/ai'
import { MainButton } from '../../../../commons/MainButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionThree = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-three')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-three',
        'section-three-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="qualidades">
      <Title>Por que escolher nossos serviços?</Title>
      <Advantages>
        <AdvantageItem
          icon={<AiOutlineBarChart />}
          title="Direcionamento integral"
          content="Aumento da produtividade, redução do estresse,
          maior engajamento e retenção de talentos."
        />
        <AdvantageItem
          icon={<AiOutlineBarChart />}
          title="Abordagem Humanizada"
          content="Entendemos as necessidades específicas da sua
          empresa e dos seus colaboradores, criando
          soluções personalizadas."
          colorful
        />
        <AdvantageItem
          icon={<AiOutlineBarChart />}
          title="Dinâmicas profundas"
          content="Experiências direcionadas para promover o bem-
          estar e a felicidade no ambiente de trabalho no
          momento presente."
        />
        <AdvantageItem
          icon={<AiOutlineBarChart />}
          title="Práticas sustentáveis"
          content="Promovemos práticas e ações que contribuem
          para um ambiente de trabalho mais saudável e
          sustentável."
          colorful
        />
      </Advantages>
      <MainButton
        title="Agendar Palestra"
        onClick={async () => {}}
        onMouseOver={async () => {
          await HandleLead(
            'mouse-over',
            'section-three',
            'section-three-main-button'
          )
        }}
      />
    </Container>
  )
}
