//Arquivo para definição das variáveis de telas da aplicação

import { css } from 'styled-components'

const sizes = {
  mobile: '767px',
  tablet: '1024px',
  laptop: '1440px',
  desktop: '1441rem',
}

export const screens = {
  mobile: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.mobile}) {
      ${styles}
    }
  `,
  tablet: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.tablet}) {
      ${styles}
    }
  `,
  laptop: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.laptop}) {
      ${styles}
    }
  `,
  desktop: (styles: TemplateStringsArray) => css`
    @media (min-width: ${sizes.desktop}) {
      ${styles}
    }
  `,
}
