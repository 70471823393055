import { MainButton } from '../../../../commons/MainButton'

import {
  Action,
  Container,
  Image,
  Content,
  Text,
  Title,
} from './SectionOne.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionOne = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-one')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-one',
        'section-one-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-one">
      <Content>
        <Title>
        Empresas prósperas, resultados excepcionais impulsionados pelo bem-estar
        </Title>
        <Text>
        Crie uma cultura de trabalho onde seus colaboradores sejam mais felizes gerando um maior engajamento, criatividade, comprometimento, mais produtividade, pois tudo isso eleva a qualidade dos resultados da sua empresa!
        </Text>
        <Action>
          <MainButton
            title="Descubra o poder do seu sorriso"
            onClick={async () => {}}
            onMouseOver={async () => {
              await HandleLead(
                'mouse-over',
                'section-one',
                'section-one-main-button'
              )
            }}
          />
        </Action>
      </Content>
      <Image />
    </Container>
  )
}
