import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7.5rem 0;
  text-align: center;
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;

  ${screens.tablet`
       padding: 4.5rem 0;
    `}

  ${screens.mobile`
       padding: 4.5rem 0;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.primary};
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  font-weight: 600;
  margin: 0.75rem 0 3.75rem;

  ${screens.tablet`
       font-size: 2rem;
       margin: 0.75rem 0 2.75rem;
    `}

  ${screens.mobile`
       font-size: 2rem;
       margin: 0.75rem 0 2.75rem;
    `}
`

export const ClientsContainer = styled.div<{ focus: number }>`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  transition: all 0.3s ease-in-out;
  margin-left: calc(
    -${(props) => props.focus} * 18% + ${(props) => props.focus + 1} * 1.5rem
  );

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${screens.tablet`
      width: 100%;
      flex-direction: row;
      font-size: 2rem;
      padding: 0 2rem;
      margin-left: 0;
      overflow: scroll;
    `}

  ${screens.mobile`
      width: 100%;
      flex-direction: row;
      font-size: 2rem;
      padding: 0 2rem;
      margin-left: 0;
      overflow: scroll;
    `}
`

export const UpRow = styled.div`
  display: flex;
  gap: 3rem;
`

export const DownRow = styled.div`
  display: flex;
  gap: 3rem;
`

export const SelectFocus = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  margin: 4.5rem auto 0;

  ${screens.tablet`
       display: none;
    `}

  ${screens.mobile`
       display: none;
    `}
`

export const SelectBar = styled.div<{ focus?: boolean }>`
  width: 4.5rem;
  height: 0.475rem;
  background: ${(props) =>
    props.focus ? props.theme.colors.primary : props.theme.colors.tertiary};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`
