import { useEffect } from 'react'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Content, Text, TextColumnWrapper, Title, Subtitle } from './SectionNine.styles'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { SecondaryButton } from '../../../../commons/SecondaryButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionNine = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-nine',
        'section-nine-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-nine">
      <Content>
        <Title>Construa um futuro mais feliz para sua empresa</Title>
        <TextColumnWrapper>
          <div className='1p'>
            <Subtitle>Parceria Eficaz</Subtitle>
            <Text>
              Trabalhamos em conjunto com você
              para alcançar os melhores
              resultados para sua empresa.
            </Text>
          </div>
          <div>
            <Subtitle>Compromisso</Subtitle>
            <Text>
              Aplicações teórica e prática da
              Ciência da Felicidade
              Construção de um novo estilo de
              vida e mentalidade.
            </Text>
          </div>
          <div>
            <Subtitle>Coração na Missão</Subtitle>
            <Text>
              Acreditamos no poder
              transformador do bem-estar e na
              felicidade como motor do sucesso.
            </Text>
          </div>
        </TextColumnWrapper>
        <SecondaryButton title="Reservar Palestra" action={() => { }} />
      </Content>
    </Container>
  )
}
