import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.div`
  min-width: 29.375rem;
  min-height: 17.5rem;
  max-width: 29.375rem;
  max-height: 17.5rem;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 1.25rem;
  padding: 2.5rem;
  text-align: left;

  ${screens.laptop`
      min-width: 28vw;
      max-width: 28vw;
      min-height: 23.5rem;
      max-height: 23.5rem;
    `}

  ${screens.tablet`
      min-width: 29.375rem;
      min-height: 17.5rem;
      max-width: 29.375rem;
      max-height: 17.5rem;
    `}

${screens.mobile`
      min-width: 29.375rem;
      min-height: 17.5rem;
      max-width: 29.375rem;
      max-height: 17.5rem;
    `}
`

export const ClientData = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`

export const ClientImage = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: #fff;
  object-fit: cover;
`

export const ClientName = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.black};
`

export const ClientFunction = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.tertiary};
`

export const Recommendation = styled.p`
  font-size: 1rem;
  font-weight: 500;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.black};
  margin-top: 1.5rem;
`

export const Stars = styled.div`
  display: flex;
  gap: 0.375rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.highlight};
  justify-self: flex-end;
  margin-top: auto;
`
