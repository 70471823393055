import { useEffect } from 'react'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, QuestionsContainer, Title } from './SectionEight.styles'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { CommonQuestion } from '../../../../commons/CommonQuestion'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionEight = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-eight')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-eight',
        'section-eight-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="faq">
      <Title>Tire sua Dúvida</Title>
      <QuestionsContainer>
        <CommonQuestion
          question="1. O que é bem-estar corporativo e por que ele é importante?"
          answer="O bem-estar corporativo refere-se às práticas e estratégias que visam melhorar a saúde física, mental e emocional dos colaboradores. Ele é importante porque colaboradores mais felizes e saudáveis tendem a ser mais produtivos, engajados e permanecem por mais tempo nas empresas, além de contribuir para um ambiente de trabalho mais positivo e sustentável."
        />
        <CommonQuestion
          question="2. Como o bem-estar e a felicidade impactam o sucesso da minha empresa?"
          answer="Investir no bem-estar e felicidade dos colaboradores aumenta a produtividade, diminui o estresse e melhora o engajamento. Além disso, empresas que promovem um ambiente de trabalho saudável tendem a reter mais talentos, o que resulta em uma equipe mais comprometida e uma cultura organizacional mais forte."
        />
        <CommonQuestion
          question="3. O que diferencia os seus serviços de bem-estar de outros no mercado?"
          answer="Nosso diferencial está em nossa abordagem humanizada e personalizada. Entendemos as necessidades específicas de cada empresa e oferecemos soluções inovadoras que transformam a cultura organizacional por meio de práticas focadas no bem-estar integral. Nossa paixão pelo bem-estar, aliada à inovação e empatia, garante resultados transformadores."
        />
        <CommonQuestion
          question="4. Quais tipos de atividades vocês oferecem para promover o bem-estar no ambiente de trabalho?"
          answer="Oferecemos palestras inspiradoras sobre temas como gestão do estresse e inteligência emocional, além de dinâmicas engajadoras e experiências imersivas voltadas para o autoconhecimento e a conexão humana. Essas atividades promovem o bem-estar físico, mental e emocional dos colaboradores."
        />
        <CommonQuestion
          question="5. Quais são os benefícios de investir em dinâmicas e experiências de bem-estar no ambiente de trabalho?"
          answer="As dinâmicas e experiências imersivas que oferecemos ajudam a melhorar o autoconhecimento, fortalecer as relações interpessoais, e aumentar a empatia e a colaboração. Esses benefícios se traduzem em equipes mais unidas, maior satisfação no trabalho e um ambiente de trabalho mais saudável e produtivo."
        />
        <CommonQuestion
          question="6. Como os serviços de bem-estar podem impactar a cultura organizacional da minha empresa?"
          answer="Nossas soluções personalizadas para o bem-estar visam não apenas melhorar a saúde dos colaboradores, mas também promover uma cultura organizacional mais feliz, colaborativa e sustentável. Ao implementar práticas de bem-estar, sua empresa verá melhorias no clima organizacional, aumento da retenção de talentos e maior eficiência em todos os níveis."
        />
      </QuestionsContainer>
    </Container>
  )
}
