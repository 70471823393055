import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: auto;
`

export const QuestionContainer = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;

  & :last-child {
    color: ${(props) =>
      props.active ? props.theme.colors.sixth : props.theme.colors.tertiary};
  }
`

export const Question = styled.p<{ active?: boolean }>`
  font-size: 1.125rem;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) =>
    props.active ? props.theme.colors.black : props.theme.colors.tertiary};
  transition: all 0.3s ease-in-out;
`

export const Answer = styled.p<{ active?: boolean }>`
  max-width: 50rem;
  max-height: ${(props) => (props.active ? '20rem' : '0')};
  overflow: hidden;
  padding-top: 1rem;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.black};
  transition: all 0.3s ease-in-out;
`
