import styled from 'styled-components'
import { screens } from '../../../../../types/screens'
import { bgpixels2 } from '../../../../../assets/images'

export const Container = styled.section`
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.white};
  padding: 4.375rem 2.75rem;
  ${screens.mobile`
      padding: 4.375rem 1.25rem;
    `}
`

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 3rem;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background: url(${bgpixels2});

  ${screens.tablet`
      padding: 2.25rem 2.5rem;
      border-radius: 1rem;
    `}

  ${screens.mobile`
      text-align: left;
      padding: 2.25rem 2.5rem;
       border-radius: 1rem;

      button {
      width:100%}
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 4.125rem;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 1.5rem;
  max-width: 52rem;

  ${screens.tablet`
       font-size: 2rem;
       margin-bottom: 1rem;
       max-width: 20rem;
       line-height: 2.5rem;
    `}

  ${screens.mobile`
       font-size: 2rem;
       margin-bottom: 1rem;
       max-width: 20rem;
       line-height: 2.5rem;
    `}
`

export const TextColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  gap: 2rem;
  div {
    flex-basis: 30%;
    text-align: center;
    padding: 0 1rem;
  }

  ${screens.tablet`
    flex-direction: column;
    align-items: center;
    div {
      flex-basis: 100%;
      border-left: none;
      padding: 0;
    }
  `}

  ${screens.mobile`
    flex-direction: column;
    align-items: center;
    div {
      flex-basis: 100%;
      border-left: none;
      padding: 0;
    }
  `}
`

export const Subtitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  max-width: 22rem;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.sixth};
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 1rem;
  flex-basis: 30%;
  box-shadow: 3px 3px 8px 3px rgba(0,0,0,0.4);


  ${screens.tablet`
       font-size: 1.5rem;
    `}

  ${screens.mobile`
       font-size: 1.25rem;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.75rem;
  color: ${(props) => props.theme.colors.tertiary};
  margin-bottom: 1rem;
  max-width: 24rem;
  text-align: center;

  ${screens.tablet`
       font-size: 0.95rem;
       margin-bottom: 1rem;
    `}

  ${screens.mobile`
       font-size: 0.9rem;
       margin-bottom: 1rem;
    `}
`