import { useEffect, useState } from 'react';
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection';
import { LeadModel } from '../../../../../types/leadModel';
import {
  ClientsContainer,
  Container,
  DownRow,
  SelectBar,
  SelectFocus,
  Text,
  Title,
  UpRow,
} from './SectionSeven.styles';
import ClientData from '@/../../src/utils/clients.json';
import { Client } from '../../../../../types/client';
import { ClientBox } from '../../../../commons/ClientBox';

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>;
  ip?: string;
  userAgent?: string;
}

export const HomeSectionSeven = ({ SendLead, ip, userAgent }: Props) => {
  const [focus, setFocus] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const timeSpent = useTimeSpentInSection('section-five');

  const ClientsRecommendations: Client[] = ClientData.clients;

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    };

    const response = await SendLead(lead);

    return response;
  };

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-seven',
        'section-seven-container',
        timeSpent / 1000
      );
  }, [timeSpent]);

  const startAutoScroll = () => {
    return setInterval(() => {
      setFocus((prevFocus) => (prevFocus + 1) % 3);
    }, 5000);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!isPaused) {
      interval = startAutoScroll();
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPaused]);

  const handleSelectBarClick = (newFocus: number) => {
    setFocus(newFocus);
    setIsPaused(true);

    setTimeout(() => {
      setIsPaused(false);
    }, 10000);
  };

  return (
    <Container id="section-seven">
      <Text>DEPOIMENTOS</Text>
      <Title>O que nossos clientes dizem?</Title>
      <ClientsContainer focus={focus}>
        <UpRow>
          {ClientsRecommendations.map((client, index) => {
            if (index > 2) return;
            return <ClientBox key={index} client={client} />;
          })}
        </UpRow>
        <DownRow>
          {ClientsRecommendations.map((client, index) => {
            if (index <= 2) return;
            return <ClientBox key={index} client={client} />;
          })}
        </DownRow>
      </ClientsContainer>
      <SelectFocus>
        <SelectBar focus={focus === 0} onClick={() => handleSelectBarClick(0)} />
        <SelectBar focus={focus === 1} onClick={() => handleSelectBarClick(1)} />
        <SelectBar focus={focus === 2} onClick={() => handleSelectBarClick(2)} />
      </SelectFocus>
    </Container>
  );
};
