import { Client } from '../../../types/client'
import {
  ClientData,
  ClientFunction,
  ClientImage,
  ClientInfo,
  ClientName,
  Container,
  Recommendation,
  Stars,
} from './ClientBox.styles'

import { FaStar } from 'react-icons/fa'
import { c1, c2, c3, c4, c5, c6, c7 } from "../../../assets/images"

interface Props {
  client: Client
}

const imageMap: Record<string, string> = {
  c1: c1,
  c2: c2,
  c3: c3,
  c4: c4,
  c5: c5,
  c6: c6,
  c7: c7
};

export const ClientBox = ({ client }: Props) => {
  return (
    <Container>
      <ClientData>
        <ClientImage src={imageMap[client.image]} alt={client.name} />
        <ClientInfo>
          <ClientName>{client.name}</ClientName>
          <ClientFunction>{client.function}</ClientFunction>
        </ClientInfo>
      </ClientData>
      <Recommendation>{client.recommendation}</Recommendation>
      <Stars>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </Stars>
    </Container>
  )
}
