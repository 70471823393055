import { useState } from 'react'
import {
  Action,
  Container,
  Content,
  Icon,
  Link,
  Links,
  LogoImage,
  MobileActions,
  MobileOptions,
} from './Header.styles'

import Logo from '../../../assets/images/logo.png'
import { MainButton } from '../MainButton'
import { IoMenu, IoCloseOutline } from 'react-icons/io5'
import { LeadModel } from '../../../types/leadModel'
import { HeaderButton } from '../HeaderButton'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Header = ({ SendLead, ip, userAgent }: Props) => {
  const [active, setActive] = useState(false)

  const HandleLead = async (interaction_type: string, session_id?: string) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      ip_address: ip,
      user_agent: userAgent,
    }
    if (interaction_type === 'click') {
      window.location.href = '/'
    }
    const response = await SendLead(lead)

    return response
  }

  return (
    <Container id="header">
      <Content active={active}>
        <LogoImage
          src={Logo}
          alt="logo"
          onClick={() => HandleLead('click', 'header-logo')}
          onMouseOver={() => HandleLead('mouse-over', 'header-logo')}
        />
        <Links>
          <Link href="#qualidades">Qualidades</Link>
          <Link href="#biografia">Biografia</Link>
          <Link href="#diferencial">Diferencial</Link>
          <Link href="#solucoes">Soluções</Link>
          <Link href="#faq">FAQ</Link>
        </Links>
        <Action>
          <HeaderButton title="Contato" action={() => {}} />
        </Action>
        <Icon>
          {active ? (
            <IoCloseOutline onClick={() => setActive(false)} />
          ) : (
            <IoMenu onClick={() => setActive(true)} />
          )}
        </Icon>
        <MobileOptions active={active}>
          {' '}
          <MobileActions>
            <Link
              href="#qualidades"
              onClick={async () =>
                await HandleLead('click', 'header-link-qualidades')
              }
            >
              Qualidades
            </Link>
            <Link
              href="#biografia"
              onClick={async () =>
                await HandleLead('click', 'header-link-about-us')
              }
            >
              Biografia
            </Link>
            <Link
              href="#diferencial"
              onClick={async () =>
                await HandleLead('click', 'header-link-services')
              }
            >
              Diferencial
            </Link>
            <Link
              href="#solucoes"
              onClick={async () =>
                await HandleLead('click', 'header-link-team')
              }
            >
              Soluções
            </Link>
            <Link
              href="faq"
              onClick={async () => await HandleLead('click', 'header-link-faq')}
            >
              FAQ
            </Link>
            <MainButton
              title="Contato"
              onClick={async () => {
                await HandleLead('click', 'header-main-button')
              }}
              onMouseOver={async () => {
                await HandleLead('mouse-over', 'header-main-button')
              }}
            />
          </MobileActions>
        </MobileOptions>
      </Content>
    </Container>
  )
}
