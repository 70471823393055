import styled, { keyframes } from 'styled-components'
import { screens } from '../../../../../types/screens'

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
}
`

export const Container = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 15.625rem;
  display: inline-flex;
  overflow-x: hidden;

  ${screens.mobile`
      height: 12.625rem;
  `}
`

export const Carousel = styled.div`
  width: min-content;
  white-space: nowrap;
  animation: ${scroll} 18s linear infinite;
  display: flex;
  align-items: center;
`

export const CarouselItem = styled.div`
  width: 10.625rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 30px 40px 0px #d4d9e833;
  border-radius: 1rem;
  margin-right: 2rem;
`

export const Image = styled.img`
  width: 5rem;
  height: auto;
`
