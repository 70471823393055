import styled from 'styled-components'
import { screens } from '../../../../../types/screens'
import { bgpixels } from '@/../../src/assets/images'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 10rem 0;
  display: grid;
  background: url(${bgpixels});
  row-gap: 6.5rem;
  grid-template-columns:
    minmax(7.5rem, 1fr) minmax(auto, 12fr),
    minmax(7.5rem, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    '..... content .....'
    '..... team    .....';

  ${screens.laptop`
      grid-template-columns:
      minmax(2.5rem, 1fr) minmax(auto, 12fr) minmax(2.5rem, 1fr);
    `}

  ${screens.tablet`
      max-width: 100vw;
      padding: 2rem 0 4rem;
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr)
      minmax(1.25rem, 1fr);
      overflow: hidden;
      row-gap: 2.5rem;
      grid-template-areas:
    '..... content .....'
    'team  team    team';
    `}

  ${screens.mobile`
      max-width: 100vw;
      padding: 2rem 0 4rem;
      grid-template-columns:
      minmax(1.25rem, 1fr) minmax(auto, 10fr)
      minmax(1.25rem, 1fr);
      overflow: hidden;
      row-gap: 2.5rem;
      grid-template-areas:
    '..... content .....'
    'team  team    team';
    `}
`
export const Content = styled.div`
  grid-area: content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${screens.tablet`
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    `}

  ${screens.mobile`
        flex-direction: column;
        gap: 1rem;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  text-align: center;
  max-width: 60rem;

  ${screens.laptop`
      font-size: 2rem;
    `}

  ${screens.tablet`
      font-size: 1.875rem;
      max-width: 38rem;
    `}

  ${screens.mobile`
      font-size: 7vw;
      max-width: 50vw;
      margin: 0 auto;
      text-align: center;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1rem;
  font-weight: 500;
  max-width: 20rem;
  color: ${(props) => props.theme.colors.tertiary};

  ${screens.laptop`
    font-size: .75rem;
  `}
  ${screens.mobile`
    display: none;
  `}
`

export const Advantages = styled.div`
  grid-area: team;
  display: flex;
  padding: 2rem 3rem;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${screens.laptop`
    padding: 0 0;
  `}
  ${screens.tablet`
      padding: 0 2rem;
    `}

  ${screens.mobile`
      padding: 0 2rem;
      flex-direction: column;
    `}
`
