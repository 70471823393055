import { Button } from './MainButton.styles'

interface Props {
  title: string
  onClick: () => Promise<void>
  onMouseOver: () => Promise<void>
}

export const MainButton = ({ title, onClick, onMouseOver }: Props) => {
  return (
    <Button onClick={() => window.open('https://wa.me/', '_blank')} onMouseOver={onMouseOver}>
      {title}
    </Button>
  )
}
