import { useEffect } from 'react'
import {
  Container,
  Content,
  Title,
  Text,
  Data,
  Image,
} from './SectionFour.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { DataItem } from '../../../../commons/DataItem'
import ImageSection from '@/../../src/assets/images/section-four-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFour = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('biografia')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'biografia',
        'biografia-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="biografia">
      <Title> Conheça a especialista <br></br> Helena Guitler Nogueira </Title>
      <Content>
        <Text>
          Desde 2016 focando no bem-estar, autoconhecimento, felicidade
          integral, experiências transformadoras no mundo corporativo.
        </Text>
        <Text>
          <ul>
            <li>Graduada em Marketing pela Universidade Anhembi Morumbi</li>
            <li>
              Líder em Yoga do Riso formada pela Escola do Riso de Portugal
              vinculada à Laughter Yoga University na Índia
            </li>
            <li>Coach de Autoconhecimento formada pela Abracoaching/RJ</li>
            <li>
              Pós-graduada em Psicologia Positiva, Autorrealização e do pela
              pela PUCRS
            </li>
            <li>
              Criadora da Capacitação do Sorrir e Excelência no atendimento
            </li>
            <li>
              Co-autora do livro bestseller internacional Somos F*das com
              lançamento no Brasil e Europa em 2022
            </li>
            <li>
              Realizou eventos e palestras em Portugal, Nova York, Inglaterra,
              Belo Horizonte, Rio de Janeiro, Paraná e São Paulo
            </li>
          </ul>
        </Text>
      </Content>
      <Data>
        <DataItem highlight="+200" text="Clientes" />
        <DataItem highlight="+100" text="Empresas" />
        <DataItem highlight="+4" text="Países" />
      </Data>
      <Image src={ImageSection} />
    </Container>
  )
}
