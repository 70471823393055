import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    primary: '#EFA34D', // Cor Primária
    secondary: '#FFEEC2', // Cor Secundária
    tertiary: '#1c1c1c', //  Cor Terciária
    quaternary: '#D6D6D6', // Cor Quaternária
    quinary: '#F2F4FC', // Cor Quintenáriapai
    sixth: '#DB660D',
    highlight: '#F5B437', // Cor de Destaque
    black: '#000000', // Preto
    white: '#FFFFFF', // Branco
  },
  fonts: {
    primary: 'Archivo, sans-serif',
    secondary: 'Inter, sans-serif',
    tertiary: 'Poppins, sans-serif',
  },
}
