import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  padding: 7rem 4.875rem 7.25rem;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${screens.tablet`
      width: 100%;
      padding: 5rem 2.5rem;
    `}

  ${screens.mobile`
      width: 100%;
      padding: 5rem 1.25rem;
    `}
`

export const Title = styled.h1`
  grid-area: title;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3rem;
  color: ${(props) => props.theme.colors.black};
  max-width: 30rem;
  text-align: center;
  margin-bottom: 4.75rem;

  ${screens.tablet`
      font-size: 2.25rem;
      margin-bottom: 4.375rem;
    `}

  ${screens.mobile`
      font-size: 2rem;
      margin-bottom: 4.375rem;
    `}
`

export const Advantages = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7.5rem;

  ${screens.laptop`
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      flex-basis: calc(50% - 0rem); // Cada item ocupa 50% da linha, menos 1rem de gap
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}

${screens.tablet`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7.5rem;
  `}

${screens.mobile`
    align-items: center;
    flex-direction: column;
  `}
`
